/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Layout = ({ children }) => {
  return (
    <div className="px-10 mx-auto text-center">
      <header className="mx-4 my-4 lg:mx-0">
        <Link
          className="underline font-bold text-lg lg:text-xl xl:text-xl"
          to="/"
        >
          Testing LiveView
        </Link>
      </header>
      <div className="antialiased text-gray-900">
        <main>{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
